body {
    margin: 0;
    font-family: 'Roboto Condensed', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

html,
body,
div,
p,
h1,
h2,
h3,
a,
span,
img {
    transition: all 0.2s ease-in-out !important;
}

.bracket-name {
  white-space: nowrap;
}

.teams-list.inner {
  width: 100%;
  justify-content: space-evenly;
}

.vs-team-name:first-child {
    text-shadow: 0px 0px 25px #ff2900;
}

.vs-team-name:last-child {
    text-shadow: 0px 0px 25px #020084;
}

.logo-strip.desktop {
    justify-content: center !important;
    gap: 3em !important;
}

a.footer-bullet-lower:last-child {
    padding-left: 0em !important;
    border-left: 0px solid rgba(238, 238, 238, 0.226) !important;
  }

.playoff-team {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.video {
    margin: 0 auto;
  }

/* Top-level */

.header-top-strip {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.teams-list.divider {
    height: 30px;
    width: 1px;
    background: #ccc;
}

.footer-teams {
    display: flex;
    gap: 1em;
    column-count: 2;
    flex-wrap: wrap;
    max-height: 300px !important;
  }

.bracket-name {
    text-transform: uppercase;
}

.teams-list.inner {
    gap: 2em;
    display: flex;
}

.banner.season {
    margin-top: 1em;
}

.teams-list.advanced,
.teams-list.pro-league {
    display: flex;
    align-items: center;
    gap: 1em;
    width: 100%;
}

.header-strip-wrapper {
    display: flex;
    gap: 1em;
}

.playoffcard-wrapper {
    display: flex;
    border: 1px solid #dfdfdf;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
}

.playoff-team {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #dfdfdf;
}

.playoff-team {
    display: flex;
    justify-content: flex-start;
    border-bottom: 1px solid #dfdfdf;
    padding: 1em;
    padding-right: 0px;
}

.playoff-group {
    max-width: 100%;
}

div#bracket3 {
    height: 520px;
    display: flex;
}

div#bracket1 {
    display: flex;
    height: 270px;
}

div#bracket2 {
    display: flex;
    height: 270px;
}

div#group5 {
    flex-direction: row !important;
    align-items: center;
}

svg {
    width: 100%;
}

.svg-wrap {
    width: 100%;
    min-width: 100px;
    height: 100%;
}

div#group4 {
    margin-top: 4em;
}

.playoff-wrapper.news-page-wrapper {
    padding: 10%;
    padding-top: 4em;
    padding-bottom: 4em;
}

.playoff-group {
    gap: 1em;
    display: flex;
}

.playoffcard-wrapper {
    height: max-content;
}

.playoff-team:last-child {
    border-bottom: 0px;
}

div#follow1 {
    height: 800px;
    display: flex;
}

div#group4 {
    justify-content: space-between;
    padding-left: 15%;
    padding-right: 15%;
}

div#group5 {
    flex-direction: row !important;
}

div#group9 {
    flex-direction: column;
}

div#group7 {
    flex-direction: column;
}

div#group5 {
    flex-direction: column;
}

div#group8 {
    align-items: center;
}

div#group1 {
    flex-direction: column;
}

div#group3 {
    align-items: center;
}

div#group2 {
    flex-direction: column;
}

div#group6 {
    align-items: center;
}

.playoff-score {
    height: 50%;
    display: flex;
    align-items: center;
    font-size: 1.2em;
    border-left: 1px solid #cfcfcf;
    padding-left: 1em;
    padding-right: 1em;
}

.playoff-team {
    gap: 1em;
    text-transform: uppercase;
}

.playoff-score {
    font-weight: 600;
}

.playoffcard-wrapper {
    max-width: max-content;
    min-width: 250px;
    display: flex;
    justify-content: space-between;
}

.playoff-teams {
    width: 100%;
    display: flex;
    flex-direction: column;
}

img.playoff-logo-team {
    max-width: 50px;
}

.navigation-wrapper {
    width: 100%;
}

.social-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    padding-left: 2vw;
    border-left: 1px solid #e1e1e1;
}

.pendingMatches.home.scroller {
    padding-left: 0em !important;
}

.navigation-top-wrapper {
    display: flex;
    justify-content: space-evenly;
}

.header-top-strip {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 1em;
    padding-bottom: 1em;
}

.header-bot-strip {
    padding-left: 10vw;
    padding-right: 10vw;
    background: #F7F7F7;
    min-height: 50px;
    padding-top: 1em;
    padding-bottom: 1em;
    border-top: 1px solid #E1E1E1;
}

a.navigation-bullet {
    transition: all 0.2s ease-in-out !important;
    color: black;
    text-transform: uppercase;
    text-decoration: none;
    font-size: 1em;
    font-weight: 500;
}

.masthead-wrapper {
    background-position-y: bottom;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}

.soc-links {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.header-strip-wrapper {
    display: flex;
    max-width: 100%;
    align-items: center;
    overflow-x: auto;
    justify-content: space-between;
    overflow-y: hidden;
}

img.featured-team-logo {
    max-width: 10em;
}

.masthead-vs {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.vs-team-wrapper {
    display: flex;
    align-items: center;
    width: max-content;
}

.vs-date {
    font-size: 1.4em;
    font-weight: 400;
}

.watch-now-icons-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1em;
    align-items: center;
}

.vs-span {
    width: 100%;
    text-align: center;
    font-size: 1.4em;
}

.vs-timer-wrapper {
    display: flex;
    font-size: 3em;
}

.hours-wrapper {
    padding-left: .5em;
    margin-left: .5em;
    border-left: 1px solid #eeeeee5e;
    padding-right: .5em;
    margin-right: .5em;
    border-right: 1px solid #eeeeee5e;
}

.minutes-wrapper {
    padding-right: .5em;
    margin-right: .5em;
    border-right: 1px solid #eeeeee5e;
}

.vs-date {
    text-transform: uppercase;
}

.cap {
    font-weight: 400;
    font-size: 14px;
}

.playoffcard-wrapper {
    color: black !important;
}

.playoff-time {
    background: #f3f3f3;
}

.playoff-inner {
    display: flex !important;
    flex-direction: row !important;
    color: black !important;
}

.playoffcard-wrapper {
    flex-direction: column;
}

.playoff-time {
    color: grey;
    text-align: center;
    padding: 0.5em;
}

@media(max-width:1450px) {
    div#group5 {
        overflow-x: scroll;
        width: max-content;
    }

    div#group5 {
        overflow-x: scroll;
        width: max-content;
        padding-left: 10%;
        padding-right: 2em;
        padding-top: 2em;
        padding-bottom: 2em;
    }

    div#group9 {
        max-width: max-content;
    }

    div#group5 {
        padding: 0px;
    }

    .playoff-wrapper.news-page-wrapper {
        padding-right: 0px;
        padding-left: 0px;
    }

    div#group5 {}

    div#group4 {
        justify-content: space-between;
        padding-left: 15%;
        padding-right: 0px;
    }

    .playoff-wrapper.news-page-wrapper {
        overflow-x: hidden;
    }

    .playoff-team a {
        margin-left: 0px;
        margin-right: 0px;
    }

    div#group8 {
        padding: 10%;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .playoff-team {
        display: flex;
        justify-content: flex-start;
        border-bottom: 1px solid #dfdfdf;
        padding: 4px;
        padding-right: 0px;
    }

    div#group4 {
        padding-right: 0px;
    }
}

@media (max-width:1100px) {
    div#group8 {
        padding: 0px !important;
        padding-top: 0px;
        padding-bottom: 0px;
    }

    .playoff-wrapper.news-page-wrapper {
        padding-right: 0px !important;
        padding-left: 0px !important;
    }

    div#group4 {
        padding-right: 15%;
    }

    div#group5 {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.days-wrapper,
.hours-wrapper,
.minutes-wrapper,
.seconds-wrapper {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
}

img[src=""] {
    background: #eee;
}

.masthead-wrapper {
    min-height: 85vh;
    background-size: cover !important;
}

.masthead-wrapper {
    background-position-y: bottom;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: max-content;
    padding-top: 3em;
    background-repeat: no-repeat;
    background-position-x: center;
}

.vs-team-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 33.33%;
    gap: 2em;
}

.logo-wrapper:hover {
    opacity: .75;
}

.vs-games-button:hover,
img.watch-now-icons:hover {
    transform: translateY(5px);
}

.masthead-wrapper.header-wrapper {
    position: relative;
}

.masthead-top,
.masthead-vs,
.vs-date,
.vs-timer-wrapper,
.vs-watch-links,
a.vs-games-button {
    z-index: 1;
}

.masthead-wrapper.header-wrapper:before {
    content: '';
    position: absolute;
    z-index: 0;
    left: 0px;
    background: #ffffff26;
    width: 100%;
    height: 100%;
    top: 0px;
    display: none;
}

.vs-timer-wrapper {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2.4em;
}

.vs-games-button {
    background: #1C5BFB;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1.4em;
    padding-bottom: 1.4em;
    display: flex;
    align-items: center;
    gap: 1em;
    color: white;
    text-decoration: none;
    font-size: 1.2em;
}

.vs-games-button {
    background: #1C5BFB;
    padding-left: 3em;
    padding-right: 3em;
    padding-top: 1.4em;
    padding-bottom: 1.4em;
}

.vs-watch-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 4em;
    border-top: 1px solid #ffffff42;
}

.vs-watch-links {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

img.watch-now-icons {
    width: 20px;
    height: 20px;
    padding: 15px;
    background: #FF1B1B;
}

.watch-now {
    text-transform: uppercase;
    margin-bottom: 1em;
}

img.logo-vs-overexposed {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.masthead-top {
    position: relative;
}

.masthead-top {
    font-size: 1.4em;
}

img.featured-team-logo.first {
    max-width: 10em;
    filter: drop-shadow(0px 0px 25px #4444dd);
}

img.featured-team-logo.last {
    max-width: 10em;
    filter: drop-shadow(0px 0px 25px #dd4e44);
}

.vs-team-logo {
    display: flex;
}

.masthead-vs {
    max-width: 100%;
    gap: 4em;
}

.vs-team-name {
    font-size: 3em;
    font-weight: 600;
    text-transform: uppercase;
}

@media (max-width:1400px) {
    .masthead-wrapper.header-wrapper {
        padding-top: 4em !important;
    }
}

.vs-watch-links {
    padding-top: 1em !important;
    margin-bottom: 2em !important;
}

td {
    font-size: 0.9em;
}

.team_wrapper img {
    max-width: 3em;
}

.team_wrapper:hover {
    transform: translateY(5px) !important;
}

a.navigation-bullet:hover {
    opacity: 0.75 !important;
}

/* Footer */

.footer-wrapper {
    background: #0C1425;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 1em;
    padding-bottom: 1em;
    height: 100%;
}

.App {
    height: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

a.footer-bullet {
    color: white;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 0.9em;
}

a.footer-bullet:hover,
a.footer-bullet-lower:hover {
    opacity: 0.5;
}

.footer-teams,
.footer-contacts,
.footer-navigation {
    display: flex;
    flex-direction: column;
    flex: 1 2;
    gap: 1em;
}

.masthead-wrapper.header-wrapper {
    margin-bottom: 2em;
}

img.img-sm {
    max-width: 40px;
}

tr:nth-child(2n) {
    border: 1px solid #eee;
}

td.playerinfo {
    text-align: left;
    gap: 1em;
    display: flex;
    align-items: center;
    font-weight: 600;
}

img.img-sm.avatar {
    background: #eee;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50em;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

thead {
    font-weight: 600;
}

.players-page-wrapper,
.ladder-page-wrapper {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2em;
    margin-bottom: 2em;
    text-transform: uppercase;
    padding: 2em;
    border: 1px solid #eee;
}

table {
    border-collapse: collapse;
}

td {
    text-align: center;
    padding: 1em;
    margin: 0px;
    width: 100%;
}

.news_container {
    margin-top: 2em;
}

a.news-show-more {
    color: red;
    text-decoration: none;
    text-transform: uppercase;
    font-weight: 600;
}

.news-text-wrapper {
    padding: 2em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    justify-content: space-between;
    height: 100%;
}

.news-title {
    font-size: 1.4em;
    font-weight: 600;
}

.article-block-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 33.33%;
    width: 100%;
    border: 1px solid #eee;
}

.fourohfour {
    text-align: center;
    width: 100%;
    margin-top: 4em;
    margin-bottom: 4em;
}

.ladder-page-wrapper,
tr.ladder-block-wrapper {
    border: 0px;
}

.match-block-wrapper {
    display: flex;
    flex-direction: row;
    flex: 50%;
}

.left-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

img.match-team-logo {
    max-width: 4em;
}

.news-page-wrapper.animate__animated.animate__fadeIn {
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2em;
    margin-bottom: 4em;
}

.hero-wrapper td {
    padding: 0.25em;
    text-transform: uppercase;
}

.ladder_container td+td {
    border-left: 1px solid#eee;
}

.ladder_container thead {
    border-bottom: 1px solid #eee;
    font-weight: 500;
}

.ladder_container td {
    padding: 1em;
    width: 75px !important;
}

.hero-left {
    display: flex;
    flex-direction: column;
    align-items: baseline;
}

.hero-right {
    width: 100%;
}

.banner.home {
    max-width: 100%;
    object-fit: cover;
    width: 100%;
}

wrapper {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    gap: 1em;
    overflow-y: hidden;
}

.pendingMatches.home .title_sm {
    margin-bottom: 1em;
    color: #80808085;
    text-transform: uppercase;
    font-weight: 600;
}

.left-wrapper .date {
    margin-bottom: 1em;
}

.pendingMatches.home {
    padding-left: 10vw;
    padding-right: 10vw;
}

.resultMatches.home {
    padding-left: 10vw;
    padding-right: 1em;
}

.match-block-wrapper {
    gap: 1em;
}

.match-team-wrapper {
    display: flex;
    align-items: center;
    gap: 2em;
    text-transform: uppercase;
    font-weight: 600;
}

.date {
    text-transform: uppercase;
    color: #b9b7b7;
    font-weight: 600;
    font-size: 0.9em;
}

.right-wrapper.time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #FF1B1B;
    border-left: 1px solid #eee;
    padding-left: 2em;
    padding-right: 1em;
}

.right-wrapper.time {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    color: #FF1B1B;
}

.pendingMatches.home {
    gap: 2em;
}

.match-vs {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 1em;
}

.hero-right {
    display: flex;
    align-items: center;
}

.match-block-wrapper {
    border: 1px solid #eee;
    padding: 1em;
}

.pendingMatches.home {
    padding-top: 2em;
    padding-bottom: 2em;
}

.hero-wrapper {
    display: flex;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 4em;
    box-shadow: 0px 55px 50px rgba(0, 0, 0, 0.05);
    padding-bottom: 4em !important;
}

table.ladder_container {
    margin-bottom: 4em;
}

a.news-show-more.home {
    background: #ff1b1b;
    color: white;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 1em;
    padding-bottom: 1em;
    margin-top: 2em;
}

.hero-wrapper {
    gap: 4em;
}

.banner.home {
    max-width: 100%;
}

.hero-wrapper {
    display: flex;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 4em;
    padding-bottom: 2em;
}

.match-vs>hr {
    border-bottom: 1px solid rgba(238, 238, 238, 0.411);
    width: 100%;
}

.news_container.home {
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 4em;
    padding-bottom: 4em;
}

.article-block-wrapper {
    display: flex;
    flex-direction: column;
    flex-basis: 30%;
}

img.news-img {
    max-width: 100%;
}

.news_container {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
}

.ladder-page-wrapper {
    text-align: center;
    display: flex;
    gap: 2em;
    flex-direction: column;
}

.ladder-page-wrapper>table>thead {
    border-bottom: 1px solid #eee;
}

.ladder-page-wrapper>table td+td {
    border-left: 1px solid #eee;
}

table.ladder_container {
    max-width: 50%;
    margin: 0 auto;
}

.teams_container {
    display: flex;
    flex-wrap: wrap;
    gap: 2em;
    justify-content: flex-start;
}

.team-block-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    border: 1px solid #eee;
    min-width: 10%;
    max-width: 10%;
}

.team-block-name {
    margin-top: 2em;
    border-top: 1px solid #eee;
    padding-top: 1em;
    width: 100%;
    text-align: center;
    text-transform: uppercase;
}

.title_lg.dark {
    font-size: 2em;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 0.5em;
}

img.img-team-logo {
    max-width: 4em;
}

.teams-page-wrapper {
    margin-top: 2em;
    margin-bottom: 2em;
    border: 1px solid #eee;
    padding: 2em;
    margin-left: 10vw;
    margin-right: 10vw;
}

.footer-wrapper {
    margin-top: 2em;
}

a.footer-bullet-lower {
    color: white;
    text-decoration: none;
}

.footer-links {
    gap: 2em;
    display: flex;
}

.footer-top-strip {
    padding-bottom: 4em;
}

.footer-disclaimer {
    color: #ffffff40;
    margin-top: 1em;
    font-size: 0.9em;
}

.footer-wrapper {
    background: #0C1425;
    padding-left: 10vw;
    padding-right: 10vw;
    padding-top: 4em;
    padding-bottom: 2em;
    height: 100%;
}

a.footer-bullet-lower:first-child {
    padding-right: 2em;
    border-right: 1px solid rgba(238, 238, 238, 0.226);
}

a.footer-bullet-lower:last-child {
    padding-left: 2em;
    border-left: 1px solid rgba(238, 238, 238, 0.226);
}

.footer-bot-strip {
    margin-top: 2em;
    padding-top: 2em;
    border-top: 1px solid #eeeeee1f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1em;
    color: white;
}

.footer-teams {
    display: flex;
    flex-direction: column;
    flex: 1 2;
    max-height: 25vh;
    flex-wrap: wrap;
    width: 100%;
    margin-right: 4em;
    column-gap: 2em;
}

.title_md {
    font-size: 1.4em;
    margin-bottom: 1em;
    font-weight: 600;
    text-transform: uppercase;
}

.team-wrapper {
    padding-left: 10vw;
    padding-right: 10vw;
}

.lineup {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
}

.matches {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.player-image {
    max-width: 100%;
}

.team-upper-section {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.player-block-wrapper {
    max-width: 100%;
    flex-basis: 16%;
}

.stat-values {
    font-weight: 600;
    font-size: 1.2em;
}

.team-logo {
    max-width: 100%;
}

.general {
    flex-basis: 40%;
}

.detail {
    flex-basis: 60%;
}

.lineup {
    gap: 1em;
}

.info {
    display: flex;
    flex-direction: column;
    gap: 1em;
    text-transform: uppercase;
}

.player-block-wrapper {
    border: 1px solid #eee;
}

.player-image {
    min-width: 150px;
    height: 150px;
    object-fit: contain;
}

.detail,
.general {
    gap: 2em;
    display: flex;
    flex-direction: column;
}

.team-upper-section {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 2em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.info-item {
    display: flex;
    width: max-content;
    gap: .5em;
    align-content: center;
}

.team-logo {
    border: 1px solid #ddd;
    background: #eee;
}

.stat-wrapper {
    display: flex;
    padding: 1em;
    border: 1px solid #eee;
    flex-direction: column-reverse;
    align-items: center;
    gap: 1em;
    row-gap: 1em;
    column-gap: 1em;
    flex-basis: 33.3%;
    text-transform: uppercase;
}

.stat-title {
    font-size: 0.8rem;
    color: gray;
}

.stats {
    padding: 1em;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    border: 1px solid #eee;
    display: grid;
    columns: 3;
    grid-template-columns: 1fr 1fr 1fr;
}

.player-info {
    display: flex;
    gap: 10px;
    text-transform: uppercase;
}

.player-country-logo {
    max-width: 20px;
}

.player-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: end;
    gap: 1em;
    padding: 1em;
}

.title_lg {
    font-size: 2em;
    margin-bottom: 1em;
    font-weight: 600;
    text-transform: uppercase;
}

/* Misc */

.news-show-more.home:hover,
.load-more:hover {
    box-shadow: 0px 15px 50px #ff000047;
}

a,
div,
img {
    border-radius: 2px;
}

.load-more {
    margin-top: 2em !important;
}

.header-bot-strip,
.masthead-wrapper.header-wrapper,
.footer-wrapper {
    border-radius: 0px;
}

.load-more {
    padding: .5em;
    padding-right: 0.5em;
    padding-left: 0.5em;
    padding-left: 2em;
    padding-right: 2em;
    background: #e5271d;
    width: max-content;
    margin-top: 4em;
    color: white;
    margin: 0 auto;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 1.2em;
}

.article-block-wrapper {
    flex: 30%;
}

.load-more:hover {
    transform: translateY(5px);
}

.match-block-wrapper {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.05);
    max-width: 30%;
}

.match-block-wrapper:hover {
    transform: translateY(-8px);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.075);
}

wrapper {
    overflow: visible;
}

.inactive {
    opacity: 0.5;
    pointer-events: none;
}

.footer-top-strip {
    display: flex;
    justify-content: space-between;
    color: white !important;
    text-decoration: none;
    align-items: flex-start;
}

.navigation-wrapper.mobile {
    display: none;
}

.news-title {
    text-transform: uppercase;
}

.navigation-wrapper-mob {
    display: none;
}

.footer-teams {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 1em;
    text-align: left;
    margin-bottom: 1em;
}

.matches-wrapper {
    padding: 10vw;
    padding-top: 2em;
    padding-bottom: 2em;
}

.spinner {
    margin: 2em;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 5px solid #d6d6d6;
    animation: spinner-bulqg1 0.8s infinite linear alternate,
        spinner-oaa3wk 1.6s infinite linear;
}

.loader.main.error {
    flex-direction: column;
}

.error-msg {
    color: #ff8e8e;
    font-weight: 700;
}

.vs-maps-wrapper {
    display: flex;
    gap: 4em;
}

.image-wrapper.map {
    display: flex;
    padding: 1em;
    min-width: 282px;
}

.details-wrapper {
    display: flex;
    padding-left: 10vw;
    padding-right: 10vw;
    gap: 4em;
    margin-top: 2em;
    margin-bottom: 2em;
}

.lineup.team1 .player-image {
    min-width: auto;
    height: 75px;
    object-fit: contain;
}

.player-info-stats {
    text-align: left;
    display: flex;
    gap: 1em;
    align-items: center;
}

.details-wrapper {
    flex-direction: column;
}

.vs-score-match {
    white-space: nowrap;
    font-size: 2em;
    font-weight: 500;
    background: red;
    padding-left: .5em;
    padding-right: .5em;
    padding-top: .25em;
    padding-bottom: .25em;
}

.team1-players-stats {
    height: max-content;
    padding: 1em;
    border: 1px solid #eee;
}

.team2-players-stats {
    height: max-content;
    padding: 1em;
    border: 1px solid #eee;
}


.image-wrapper.map.loaded {
    padding: 0px;
}

.players-stats {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.lineups-wrapper {
    display: flex;
    gap: 1em;
    max-width: 100%;
}

.player-country-logo {
    object-fit: contain;
}

.lineup.team1 {
    width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: max-content;
}

.lineup.team2 .player-info {
    min-height: 25px;
}

.lineup.team1 .player-info {
    min-height: 25px;
}

.lineup.team2 {
    width: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    height: max-content;
}

a,
div {
    text-decoration: none;
}

.image-wrapper.map {
    justify-content: center;
    align-items: center;
}

.lineup.team1 .image-wrapper {
    justify-content: center;
}

.lineup.team2 .image-wrapper {
    justify-content: center;
}

.lineup.team1 a {}

.player-block-wrapper {
    width: 6em;
    height: 6em;
}

.lineup.team1 {
    justify-content: flex-start !important;
}

.player-block-wrapper {
    width: 100px;
    height: 120px;
    align-items: center;
    justify-content: space-between;
}

.lineup.team1 {
    flex-basis: 50%;
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
}

.lineup.team2 {
    flex-basis: 50%;
    box-shadow: 0px 25px 50px rgba(0, 0, 0, 0.05);
}

.lineup.team2 {
    justify-content: flex-end !important;
}

.lineup.team1 {
    justify-content: space-evenly;
}

.player-info .title_sm {
    word-break: break-all;
    font-size: 0.9em;
}

.lineup.team2 {
    justify-content: space-evenly;
}

.lineup.team2 a {}

.lineup.team2 .player-image {
    min-width: auto;
    height: 75px;
    object-fit: contain;
}

.map-score {
    padding: 1em;
    background: #1C5BFB;
    display: flex;
    justify-content: space-between;
    gap: 4em;
    text-transform: uppercase;
}

.image-wrapper.map {
    background: white;
}

.spinner.error {
    margin: 2em;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border: 5px solid #ff8e8e;
    animation: spinner-bulqg1 5s infinite linear alternate,
        spinner-oaa3wk 10s infinite linear;
}

.news-top {
    display: flex;
    gap: 1em;
    flex-direction: column;
}

.news-top .news-title {
    font-size: 2em;
}

.news-top .news-description {
    font-size: 1.2em;
}

.article-page-wrapper {
    display: flex;
    margin-left: 10vw;
    margin-right: 10vw;
    margin-top: 2em;
    margin-bottom: 1em;
    padding: 2em;
    border: 1px solid #eee;
}

.news-content {
    color: #6a6a6a;
}

.arrow-slider {
    display: flex;
    width: max-content;
    gap: 0px;
    overflow-x: hidden;
}

.arrow-slider-wrapper {
    overflow: hidden;
}

.scroller-wrapper {
    overflow-x: scroll;
}

.scroller-wrapper::-webkit-scrollbar {
    display: none;
}

.arrow-slider .match-block-wrapper {
    max-width: 20vw;
    min-width: 20vw;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
}

.arrow-left {
    top: 0px;
    left: 0px;
    height: 100%;
    background: white;
    width: calc(10vw - 4em);
    position: absolute;
    box-shadow: 10px 0px 25px rgba(0, 0, 0, 0.05);
    z-index: 99;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1em;
    cursor: pointer;
}

.hero-protector {
    display: flex;
}

.arrow-left img {
    min-width: 25px;
}

.arrow-right img {
    min-width: 25px;
}

.hero-wrapper {
    display: flex;
    flex-direction: column;
}

.news-show-more.home {
    width: max-content;
}

.banner.home {
    max-width: unset;
    height: 100%;
}

.hero-wrapper {
    gap: 0px;
}

.hero-left {
    border: 1px solid #eee;
    padding: 2em;
}

.hero-inner {
    display: flex;
    gap: 1em;
    padding: 2em;
    border: 1px solid #eee;
}

.arrow-right {
    top: 0px;
    right: 0px;
    height: 100%;
    background: white;
    width: calc(10vw - 4em);
    position: absolute;
    box-shadow: -10px 0px 25px rgba(0, 0, 0, 0.05);
    z-index: 99;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 1em;
    cursor: pointer;
}

.scroller-outer {
    position: relative;
    overflow: hidden;
}

.arrow-slider {
    position: relative;
}

.scroller-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
    position: relative;
}

.week-section-wrapper {
    display: flex;
    gap: 1em;
    flex-wrap: wrap;
    justify-content: start;
}

.news-page-text-wrapper {
    display: flex;
    flex-direction: column;
    padding: 2em;
    justify-content: space-between;
    width: 100%;
    gap: 2em;
}

.match-team-score {
    font-size: 2em;
}

.match-team-title {
    align-items: center;
}

.pendingMatches.home {
    overflow-x: hidden;
}

.news-page-text-wrapper {
    margin-left: 2em;
    padding-left: 2em;
    border-left: 1px solid #eee;
}

.news-item-wrapper {
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 2em;
}

.news-page-text-wrapper {
    align-self: stretch;
    flex-basis: 50%;
    /* flex: 1; */
}

.week-title-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.news-date {
    background: #eee;
    width: max-content;
    padding: 1em;
    padding-top: 0.5em;
    padding-bottom: 0.5em;
}

.news-top {
    width: 100%;
    padding-bottom: 2em;
    border-bottom: 1px solid #eee;
}

.week-title {
    width: max-content;
    white-space: nowrap;
}

.week-title-wrapper .hr {
    width: 100%;
    margin-left: 1em;
    border: 1px solid #eee;
}

.week-title {
    text-transform: uppercase;
    padding: 1em;
    padding-left: 2em;
    padding-right: 2em;
    background: #FF1B1B;
    width: max-content;
    color: white;
    margin-top: 2em;
    margin-bottom: 2em;
}

.match-team-title {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.news-show-more:hover {
    transform: translateY(5px);
}

.match-vs>hr {
    border-bottom: 1px solid rgba(238, 238, 238, 0.411);
    width: 100%;
    opacity: .1;
}

.player-block-wrapper .image-wrapper {
    background: url(../public/img/logo_insert.svg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-size: auto;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    display: flex;
}

.status {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 2em;
}

@keyframes spinner-bulqg1 {
    0% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 50% 0%, 50% 0%, 50% 0%, 50% 0%);
    }

    12.5% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 0%, 100% 0%, 100% 0%);
    }

    25% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 100% 100%, 100% 100%);
    }

    50% {
        clip-path: polygon(50% 50%, 0 0, 50% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    62.5% {
        clip-path: polygon(50% 50%, 100% 0, 100% 0%, 100% 0%, 100% 100%, 50% 100%, 0% 100%);
    }

    75% {
        clip-path: polygon(50% 50%, 100% 100%, 100% 100%, 100% 100%, 100% 100%, 50% 100%, 0% 100%);
    }

    100% {
        clip-path: polygon(50% 50%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 50% 100%, 0% 100%);
    }
}

@keyframes spinner-oaa3wk {
    0% {
        transform: scaleY(1) rotate(0deg);
    }

    49.99% {
        transform: scaleY(1) rotate(135deg);
    }

    50% {
        transform: scaleY(-1) rotate(0deg);
    }

    100% {
        transform: scaleY(-1) rotate(-135deg);
    }
}

@keyframes flashbang {
    0% {
        opacity: 1;
        transform: rotate(0deg);
    }

    49.99% {
        opacity: 0.25;
        transform: rotate(-30deg);
    }

    100% {
        opacity: 1;
        transform: rotate(0deg);
    }
}


.arrow-slider .match-block-wrapper {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    min-height: 100%;
}

.matches-section-wrapper-pending {
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
}

.pendingMatches.home.scroller {
    padding-left: 10vw !important;
}

.masthead-wrapper.header-wrapper {
    background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgb(255, 255, 255) 0%, rgb(232, 232, 232) 100%);
}

.loader.main {
    min-height: 100vh;
}

.spinner.main {
    width: 75px;
    height: 75px;
}

.preloader {
    max-width: 50px;
    animation: flashbang 1s ease-in-out infinite;
    z-index: 1;
    position: absolute;
}

.team-lineup-title {
    display: flex;
    flex-direction: row;
    font-size: 1.4em;
    text-transform: uppercase;
    align-items: center;
    gap: 10px;
}

.team-logo-lineup {
    max-width: 100%;
}

.lineup-team {
    margin-top: 1em;
    box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
    border: 1px solid #eee;
}

.lineup-team {
    display: flex;
}

.lineups-wrapper {
    justify-content: space-between;
}

.team-logo-lineup {
    max-width: 100%;
}

.image-wrapper.team-lineup-title-wrapper {
    max-width: 25px;
}

img {
    max-width: 100%;
}

.modal {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #00000073;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hidden {
    display: none;
}

.modal-inner {
    width: 50%;
    height: 50%;
    background: white;
}

.masthead-wrapper.header-wrapper {
    background-position-x: center !important;
}

.stat-wrapper {
    text-align: center;
}

.right-wrapper.time {
    padding-left: 1em;
    text-align: center;
    padding-right: 0px;
    font-size: 0.8em;
}

@media (min-width:1100px) {
    .footer-teams {
        display: flex;
        gap: 1em;
        max-height: 100%;
    }

    .vs-team-wrapper:last-child {
        justify-content: flex-end;
    }

    .matches-section-wrapper.matches-results {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 1em;
    }

    .masthead-vs {
        max-width: 90%;
        width: 100%;
    }

    .hero-right {
        max-width: 50% !important;
    }

    .ladder_container {
        margin-left: 0px !important;
    }

    table.ladder_container {
        margin: 0 auto;
        margin-left: auto;
        width: 100% !important;
        max-width: 100% !important;
    }

    .arrow-slider .match-block-wrapper {
        height: 200px;
    }

    .matches.history {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .match-time {
        font-size: 1.4em !important;
    }

    .week-section-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .matches-section-wrapper-pending {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }

    .match-block-wrapper {
        max-width: 100% !important;
    }

    .team1-players-stats {
        width: 100%;
    }

    .map-wrapper.map:hover {
        transform: translateY(5px);
        cursor: pointer;
    }

    .team2-players-stats {
        width: 100%;
    }

    .players_container {
        margin: 0 auto;
    }

    .playerinfo {
        min-width: 200px;
    }

    .players_container thead tr td:first-child {
        max-width: 100%;
        width: 100%;
        padding: 1em;
        min-width: 200px;
        gap: 1em;
        display: flex;
    }

    .players_container {
        max-width: 100%;
        width: 100%;
    }

    .players_container thead tr {
        max-width: 100%;
        width: auto !important;
        padding: 1em;
        gap: 1em;
        display: flex;
    }
}

.players_container thead tr td {
    white-space: nowrap;
}

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

img.img-sm.avatar {
    background: #eee;
    max-width: 50px;
    max-height: 50px;
    border-radius: 50em;
    width: 100%;
    height: 100%;
    object-fit: contain;
    width: 30px;
    height: 30px;
}

.loader.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.article-block-wrapper {
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.075);
}

.news_container {
    gap: 1em;
}

.player-block-wrapper {
    color: black;
    text-decoration: none !important;
}

.image-wrapper.player-pic-wrapper {
    max-height: 500px;
    overflow: hidden;
}

.general {
    position: relative;
}

.lineup {
    padding: 1em;
    border: 1px solid #eee;
}

.team-logo-full {
    max-width: 100%;
}

.image-wrapper.team {
    padding: 3em;
    background: #eee;
    border: 1px solid #ddd;
}

.image-wrapper.player-team-logo-absolute {
    position: absolute;
    top: 0px;
    right: 0px;
    max-width: 100px;
}

.image-wrapper.player-team-logo-absolute .team-logo {
    border: 0px;
    background: transparent;
}

.match-link {
    margin: 0;
    display: flex;
    gap: 1em;
    color: black;
    -webkit-text-decoration: none aliceblue;
    text-decoration: none aliceblue;
    justify-content: space-between;
    width: 100%;
}

.players_container thead tr {
    max-width: 100%;
    width: 100%;
    padding: 1em;
    gap: 1em;
    display: flex;
}

.linkless {
    display: flex;
    width: 100%;
    text-decoration: none;
    color: black;
}

.image-wrapper.player-img-wrapper {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #eee;
    background: url(../public/img/logo_insert.svg);
    background-position-x: 0%;
    background-position-y: 0%;
    background-repeat: repeat;
    background-size: auto;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
}

.stats {
    margin-bottom: 1em;
}

.player-img {
    max-width: 100%;
}

.player-block-outer-wrapper {
    padding: 1em;
    color: black;
    gap: 10px;
    display: flex;
    text-align: center;
    flex-direction: column;
}

.player-block-outer-wrapper .player-info {
    width: max-content;
    margin: 0 auto;
}

.no-results {
    margin: 0 auto;
    display: flex;
    gap: .5em;
    align-items: center;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 1.2em;
    min-height: 50vh;
    color: gray;
    cursor: pointer;
}

.scroller-wrapper {
    scroll-behavior: smooth;
}

.title-skewed {
    position: absolute;
    top: -2em;
    width: 170px;
    height: max-content;
    color: white;
    font-size: 1.2em;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -12px;
}

.match-vs-team-wrapper .match-block-wrapper-bg {
    height: max-content;
    max-width: 30% !important;
    width: 100%;
}

.match-block-wrapper-bg a {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-bottom: 1em;
}

.match-vs-team-wrapper:first-child {
    justify-content: start;
}

.footerMatches wrapper {
    overflow: hidden !important;
}

.match-vs-team-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-basis: 50%;
}

.footerMatches {
    padding-left: 10vw;
    padding-right: 10vw;
    overflow: scroll;
}

.match-block-wrapper-bg {
    height: max-content;
    background-repeat: no-repeat;
}

.footerMatches wrapper {
    overflow: scroll;
    width: max-content;
    display: flex;
    flex-direction: row;
    gap: 1em;
}

.news_container.home {
    position: relative;
}

.title-skewed img {
    position: absolute;
    width: 100%;
    max-width: 100%;
    z-index: -1;
}

.footerMatches-vs {
    padding-left: 10vw;
    padding-right: 10vw;
}

.match-bg-vs-span {
    color: white;
}

.match-bg-vs-watch-links {
    color: black;
    justify-content: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2em;
}

.match-bg-vs-span {
    margin-bottom: -4em;
    font-size: 1.2em;
}


.match-bg-vs-date {
    word-break: break-word;
    color: white;
    text-transform: uppercase;
    font-size: 1.2em;
}

.match-team-logo-bg {
    width: 75px;
}

.footerMatches-vs wrapper {
    display: flex;
    flex-wrap: nowrap !important;
}

.match-bg-vs-date {
    color: white;
}

.match-bg-vs {
    padding-bottom: 4em !important;
}



.match-bg-vs-inner-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.match-bg-vs {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.match-bg-vs {
    padding: 1em;
}

.match-bg-vs-watch-links {
    padding-bottom: 1em;
}

.match-block-wrapper-bg-vs {
    border: 1px solid #eee;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.1);
}

.match-bg-now-icons-wrapper {
    gap: 1em;
    display: flex;
}

.match-bg-vs {
    min-height: 350px;
    background-position-y: bottom;
    background-size: cover;
    background-position-x: center;
}

.match-bg-vs-date {
    color: black;
    margin: 0 auto;
    margin-top: 0px;
    width: max-content;
    margin-top: 2em;
}

.match-block-wrapper-bg-vs {
    flex-basis: 33.333%;
}

.match-bg-vs-team-wrapper:last-child {
    justify-content: end;
}

.match-bg-vs-team-logo {
    max-width: 100px;
}

.match-block-wrapper-bg-vs {
    flex-basis: 33.33333333%;
    max-width: 100%;
}

.match-bg-vs-team-wrapper {
    display: flex;
    color: white;
    align-items: center;
    text-transform: uppercase;
}

.match-bg-vs-team-name {
    font-size: 1.2em;
    font-weight: 600;
}

.match-bg-vs-team-wrapper {
    max-width: 100%;
    flex-basis: 50%;
}

.match-bg-vs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footerMatches-vs wrapper {
    display: flex;
    flex-wrap: wrap;
}

.match-bg-vs-date {
    padding-top: 0px !important;
    color: white !important;
}

.docs.news-page-wrapper.animate__animated.animate__fadeIn>.title_md {
    margin-top: 1em !important;
}

.match-team-logo-bg {
    width: 75px;
    max-width: 75px;
}

.docs-wrapper {
    margin-top: 1em;
}

.footerMatches-vs {
    padding-left: 0px;
}

.match-bg-vs {
    padding-left: 5px;
    padding-right: 5px;
}

.footerMatches-vs {
    padding-left: 10vw;
}

.title-skewed.footer {
    top: 20px;
}

.modal-inner {
    width: 50%;
    height: max-content;
    background: white;
    padding: 1em;
    color: black;
}

.footerMatches-vs {
    padding-top: 6em;
    position: relative;
    box-shadow: inset 0px 25px 25px rgba(0, 0, 0, 0.05);
}

.close-modal {
    position: absolute;
    top: 0px;
    left: 0px;
    padding: 2em;
    cursor: pointer;
    font-size: 2em;
}

.banner.home {
    max-width: unset;
    height: 100%;
    min-width: 500px;
}

.footer-teams {
    display: flex;
    gap: 1em;
}

@media (max-width:800px) {
    .vs-team-name {
        font-size: 1em !important;
    }
}

.vs-team-name:first-child {
    text-align: right !important;
}

@media (max-width: 1100px) {

    .teams-list.advanced.mobile,
    .teams-list.pro-league.mobile {
        flex-direction: column;
        align-items: baseline;
    }

    .bracket-name.mobile {
        padding: 1em;
        background: #eee;
        width: 100%;
        text-align: center;
    }

    .outer-strip {
        padding: 1em;
    }

    .header-strip-controller {
        display: flex;
        width: 100%;
    }

    .header-strip-controller {
        margin-bottom: 1em;
    }

    .bracket-name.active {
        background: #ff0c0c;
        width: 100%;
        text-align: center;
        color: white;
    }

    .bracket-name {
        background: #eee;
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
        text-align: center;
        cursor: pointer;
    }

    .active-list-tab .bracket-name.mobile {
        padding: 1em;
        background: #ff0c0c;
        width: 100%;
        text-align: center;
        color: white;
    }

    .hidden-list-tab {
        display: none !important;
    }

    .teams-list.advanced.mobile,
    .teams-list.pro-league.mobile {
        flex-direction: column;
        align-items: baseline;
        width: max-content;
        padding-bottom: .5em;
    }

    .header-strip-wrapper.mobile-teams-list {
        flex-direction: column;
    }

    .logo-strip-img {
        object-fit: contain;
        max-height: 60px;
    }

    .teams-list.inner.advanced.mobile,
    .teams-list.inner.pro-league.mobile {
        flex-direction: row;
    }

    .vs-team-name:first-child {
        text-align: right;
    }

    .vs-span {
        margin-top: -70px;
    }

    .footer-teams {
        display: flex;
        gap: 1em;
        height: 100%;
        max-height: 100%;
    }

    .matches-section-wrapper.matches-results {
        display: flex;
        gap: 1em;
        flex-direction: column;
    }

    .ladder-page-wrapper {
        padding: 0px;
        margin: 0px;
        padding: 1em;
    }

    .news-title {
        font-size: 0.8em !important;
    }

    .header-top-strip .logo-wrapper {
        justify-content: flex-start !important;
        width: max-content;
        margin-left: 0px;
    }

    .news-date {
        background: transparent;
        padding: 0px;
    }

    .modal-inner {
        width: 100%;
        margin: 1em;
    }

    table.ladder_container {
        max-width: 100%;
        width: 100% !important;
    }

    .hero-left {
        max-width: 100%;
        width: 100% !important;
        margin-right: 1em;
    }

    .ladder-block-wrapper {
        height: 55px;
    }

    .subwrapper {
        width: 100% !important;
    }

    .table-wrapper {
        width: 100% !important;
    }

    .modal-inner .team1-players-stats {
        margin-right: 0px;
    }

    .close-modal {
        position: absolute;
        top: 0px;
        left: 0px;
        padding: .5em;
        padding-top: 0.5em;
        cursor: pointer;
        font-size: 2em;
        padding-top: 0px;
    }

    .modal-inner .team2-players-stats {
        margin-right: 0px;
    }

    .modal-inner .players-stats {
        gap: .5em;
    }

    .modal-inner .stats-vs-img {
        display: none;
    }

    .ladder_container td {
        padding: 1em;
        width: 10px !important;
        padding: 2px;
    }

    .ladder_container thead tr td {
        width: 25px;
        font-size: 0.85em;
    }

    .ladder_container td+td {
        border: 0px;
    }

    .ladder_container thead tr td {
        padding-bottom: 1em;
    }

    .ladder_container .img-sm {
        max-width: 25px !important;
    }

    .ladder-block-wrapper {
        border-top: 1px solid #eee !important;
        min-height: 30px !important;
    }

    .hero-left {
        padding: 0px;
        border: 0px;
    }

    .news-date {
        font-size: 0.8em;
    }

    .news-title {
        font-size: 0.8em;
    }

    .news-show-more {
        font-size: 0.9em;
    }

    .masthead-wrapper.header-wrapper {
        background-position-y: bottom !important;
        background-repeat: no-repeat !important;
        background-position-x: center;
    }

    .arrow-slider .match-block-wrapper {
        min-width: 300px !important;
        min-height: 200px !important;
    }

    .pendingMatches.home.scroller {
        padding-left: 0px !important;
    }

    .matches-section-wrapper-pending {
        flex-direction: column;
    }

    .footerMatches-vs {
        padding-left: 0vw !important;
    }

    .footerMatches-vs wrapper {
        flex-direction: column !important;
    }

    .footerMatches-vs {
        padding-left: 1em;
        padding-right: 1em;
    }

    .title-skewed {
        display: none;
    }

    .arrow-slider .match-block-wrapper {
        max-width: 100%;
        min-width: 20vw;
        height: 100%;
        max-height: 100%;
        min-height: 100%;
    }

    .arrow-left,
    .arrow-right {
        display: none;
    }

    .article-block-wrapper .img-wrapper {
        max-width: 33%;
        min-width: 200px;
        min-height: 200px;
    }

    .lineup-team {
        width: max-content;
        overflow-x: scroll;
    }

    .vs-maps-wrapper {
        flex-direction: column;
        gap: 1em;
    }

    .team-lineup-title {
        font-size: 1em;
    }

    .lineup-team-outer {
        overflow-x: scroll;
    }

    .player-block-wrapper {
        justify-content: space-between !important;
    }

    .lineup.team1 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .lineup.team2 {
        grid-template-columns: 1fr 1fr 1fr;
    }

    .lineups-wrapper {
        flex-direction: column;
    }

    .details-wrapper {
        padding-left: 1em;
        padding-right: 0em;
    }

    .team1-players-stats {
        margin-right: 1em;
    }

    .team2-players-stats {
        margin-right: 1em;
    }

    .stats-vs-img {
        margin-right: 1em;
    }

    .stats-vs-img {
        max-width: 100%;
    }

    .lineup.team1 {
        grid-template-columns: 1fr 1fr;
    }

    .lineup.team2 {
        grid-template-columns: 1fr 1fr;
    }

    .lineup.team1,
    .lineup.team2 {
        width: 100%;
    }

    .player-block-wrapper {
        flex-direction: column !important;
    }

    .vs-score-match {
        font-size: 1em;
    }

    .players-stats {
        flex-direction: column;
    }

    .player-block-wrapper {
        flex-direction: column;
    }

    .vs-team-wrapper:last-child {
        justify-content: end !important;
    }

    .vs-team-wrapper:first-child {
        justify-content: start !important;
    }

    .masthead-vs {
        max-width: 100%;
        gap: .5em;
        width: 100%;
    }

    .player-block-wrapper .player-info {
        min-width: 30%;
    }

    .footer-top-strip .logo-wrapper img {
        width: 150px;
    }

    .players_container {
        width: max-content;
    }

    .player-block-wrapper {
        flex-direction: row;
        max-width: unset;
        width: 100%;
    }

    .players_container thead {
        max-width: 100%;
        display: flex;
        width: 100%;
    }

    .players_container thead tr {
        max-width: 100%;
        width: 100%;
    }

    .news-page-text-wrapper {
        margin-left: 0px;
        border-left: 0px;
        padding-left: 0px;
        padding-right: 0px;
    }

    .players_container thead tr td:first-child {
        min-width: 250px;
    }

    .article-page-wrapper {
        flex-direction: column;
        margin-left: 1em;
        margin-right: 1em;
    }

    .players-page-wrapper {
        overflow-x: scroll;
        margin-left: 1em;
    }

    .playerinfo {
        min-width: 250px;
    }

    .matches-wrapper {
        padding-left: 1em;
        padding-right: 1em;
    }

    .teams-page-wrapper {
        margin-left: 1em;
        margin-right: 1em;
    }

    .players-page-wrapper {
        margin-right: 0px;
        padding-right: 0px;
    }

    .team-block-wrapper {
        max-width: 100%;
        width: 100%;
    }

    .team-upper-section {
        flex-direction: column;
    }

    .match-block-wrapper {
        flex: 1 !important;
        max-width: 100%;
    }

    .right-wrapper.time {
        padding-left: 15px;
        padding-right: 0px;
    }

    .matches {
        flex-direction: column;
    }

    .player-block-wrapper {
        width: 100%;
        max-width: 100%;
        flex-basis: 100%;
        justify-content: center;
        align-items: center;
    }

    .stats {
        columns: 1;
        grid-template-columns: 1fr;
    }

    .player-block-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .lineup a {
        width: max-content;
        max-width: 100%;
        display: flex;
    }

    .team-wrapper {
        padding-left: 1em;
        padding-right: 1em;
    }

    .lineup {
        grid-template-columns: 1fr;
        display: grid;
    }

    .news-page-wrapper {
        margin: 1em !important;
    }

    .navigation-wrapper {
        display: none;
    }

    .news-show-more {
        margin-left: 0px;
    }

    .logo-wrapper {
        display: flex;
        justify-content: start;
        align-items: start;
    }

    .navigation-bullet.mobile {
        background: #eee;
        padding: .5em;
        padding-right: 0.5em;
        padding-left: 0.5em;
        padding-left: 1em;
        padding-right: 1em;
        border-radius: 6px;
        color: gray;
    }

    .navigation-top-wrapper.mobile {
        display: flex;
        gap: 2em;
        padding: 1em;
        width: max-content;
        overflow-x: scroll;
        justify-content: flex-start;
        align-items: center;
    }

    .article-block-wrapper {
        width: 100%;
        max-width: 100%;
        flex: 1;
    }

    .header-bot-strip {
        overflow-x: hidden;
        padding-left: 0em !important;
        padding-right: 0em !important;
    }

    .header-strip-wrapper {
        width: max-content;
        overflow-x: scroll;
        gap: 2em;
        padding-left: 1em;
    }

    .header-top-strip {
        padding-left: 1em;
        padding-right: 1em;
    }

    .navigation-wrapper.mobile {
        display: flex;
    }

    .navigation-wrapper-mob {
        display: flex;
    }

    .social-wrapper {
        border-left: 0px;
    }

    .logo-wrapper img {
        max-width: 75%;
    }

    .soc-links {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 1em;
    }

    .soc-links img {
        max-width: 20px;
    }

    .footer-wrapper {
        background: white;
        color: #696969 !important;
    }

    .footer-wrapper .title_md,
    .footer-bullet {
        color: #696969 !important;
    }

    .article-block-wrapper {
        flex-direction: row;
        max-width: 100%;
    }

    .news-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .news-text-wrapper {
        padding: 1em;
    }

    .news-description {
        display: none;
    }

    .news-title {
        font-size: 1em;
    }

    .article-block-wrapper {
        flex-direction: row;
        max-width: 100%;
        height: max-content;
    }

    .news_container.home {
        padding-left: 1em;
        padding-right: 1em;
    }

    a.news-show-more {
        color: #ff1b1b;
        font-size: 0.8;
        text-decoration: none;
        text-transform: uppercase;
        font-weight: 500 !important;
    }

    .news_container {
        display: flex;
        flex-wrap: nowrap;
        gap: 2em;
        flex-direction: column;
    }

    .table-wrapper {
        overflow-x: scroll;
        width: max-content;
        max-width: 100%;
    }

    .news-show-more.home {
        width: max-content;
        margin: 0 auto;
        margin-top: 0px;
    }

    .article-block-wrapper .img-wrapper {
        max-width: 33%;
    }

    .article-block-wrapper {
        min-height: 200px;
    }

    .subwrapper {
        width: max-content;
    }

    .pendingMatches.home {
        padding-left: 0em;
        padding-right: 0px;
    }

    .hero-left {
        overflow-x: hidden;
        max-width: 100%;
    }

    .hero-wrapper {
        padding-left: 1em;
        padding-right: 0px;
        overflow-x: hidden;
        flex-direction: column;
    }

    .hero-right {
        display: none;
    }

    table.ladder_container {
        max-width: 100%;
    }

    img.featured-team-logo {
        max-width: 4em;
    }

    .masthead-top {
        text-align: center;
        font-size: 0.9em;
    }

    .logo-vs-overexposed {
        max-width: 25%;
    }

    .vs-team-name {
        font-size: 1.4em;
    }

    .vs-team-wrapper {
        gap: .5em;
    }

    .masthead-vs {
        max-width: 100%;
        gap: .5em;
    }

    .featured-team-logo {
        max-width: 4em !important;
    }

    .masthead-wrapper.header-wrapper {
        background-size: contain !important;
        background-position-y: top;
    }

    wrapper {
        padding-left: 1em;
    }

    .pendingMatches.home .title_sm {
        padding-left: 1em;
    }

    .footer-teams-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .footer-teams {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 1em;
        text-align: left;
        margin-bottom: 1em;
    }

    .logo-wrapper img {
        max-width: 100%;
    }

    .footer-bullet {
        width: 100%;
    }

    .footer-bullet-lower,
    .footer-disclaimer {
        color: gray !important;
    }

    .footer-bot-strip {
        margin-top: 0px;
    }

    .footer-top-strip {
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
    }

    .footer-links {
        gap: 0px;
        display: flex;
        font-size: 0.75em;
    }

    .footer-bot-strip {
        border-top: 1px solid #eee;
    }

    .logo-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    a {
        margin: 0 auto;
    }

    .logo-wrapper {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
    }

    .masthead-wrapper.header-wrapper {
        background-size: cover !important;
        background-position-y: top;
        padding-top: 10px !important;
    }

    .footer-wrapper {
        padding-left: 1em;
        padding-right: 1em;
    }

    .footer-top-strip {
        flex-direction: column;
        gap: 2em;
        column-gap: 2em;
        column-gap: 0px;
    }
}

.article-block-wrapper:hover {
    transform: translateY(-8px);
    box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.075);
}

.logo-strip.desktop {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}

.logo-strip.mobile {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 2em;
    align-items: center;
}

.logo-strip.mobile .image-wrapper {
    flex: 33.33% 1;
    max-width: 150px;
    margin-bottom: 25px;
}

.logo-strip-img {
    width: 100%;
}
